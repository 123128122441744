import fslightbox from "fslightbox";
export default class Gallery {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.wrapperEl = this.root.querySelector('[data-js-gallery-wrapper]');
        this.moreEl = this.root.querySelector('[data-js-gallery-more]');

        this.settings = JSON.parse(this.root.getAttribute('data-settings'));
        this.galleryData = JSON.parse(
            this.root.getAttribute('data-js-gallery')
        );
        this.currentPage = 1;
        this.maxPages = parseInt(this.settings.max_pages);

        this.html = this.wrapperEl.innerHTML;
        this.galleryInstance = null;

        return true;
    }

    init() {
        this.setMoreButton();
        this.setLightbox();
    }

    setMoreButton() {
        if (!this.moreEl) return;

        this.onMore = this.onMoreButton.bind(this);
        this.moreEl.addEventListener('click', this.onMore);
    }

    async onMoreButton() {
        this.currentPage += 1;

        await this.loadGallery();

        if (this.currentPage >= this.maxPages) {
            this.moreEl.classList.add('d-none');
        }
    }

    async loadGallery() {
        const formData = new FormData();
        if (this.galleryData) formData.append('gallery', this.galleryData);
        if (this.currentPage) formData.append('page', this.currentPage);

        const request = await fetch(`${home_url}/wp-json/gallery/v1/get`, {
            method: 'POST',
            body: formData,
        });

        const res = await request.json();
        const { html } = res;

        this.html = this.html + html;
        this.wrapperEl.innerHTML = this.html;

        this.setLightbox();
    }

    setLightbox() {
        const galleries = this.root.querySelectorAll(
            '.gallery-tabs-gallery__item'
        );

        let sources = [];

        galleries.forEach(item => {
            const url = item.getAttribute('href');
            sources.push(url);
        })

        this.galleryInstance = new FsLightbox();
        this.galleryInstance.props.sources = sources;

        galleries.forEach((item, index) => {
            item.addEventListener('click', (e) => {
                e.preventDefault();
                console.log(index);

                this.galleryInstance.open(index);
            })
        })
    }
}
